import React from 'react';

import User from './User';
import Group from './Group';
import Popup from './Popup';

import 'react-table/react-table.css'

class Users extends React.Component {
    render() {
        return (
            <div className="column is-10">
                <div className='columns'>
                    <div className="column is-9"><User/></div>
                    <div className="column is-3"><Group/></div>
                </div>
                <Popup/>
            </div>
        )
    }
}

export default Users;