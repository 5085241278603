const initialState = {
    data : {}
};


export default function SendListUserState(state = initialState, action) {
    switch (action.type) {
        case 'SEND_LIST_USER_LOAD':
            return  Object.assign({}, state, {
                data : action.data
            });
        default:
            return state
    }
}