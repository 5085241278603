import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Users from "../Users";
import Sends from "../Sends";
import Graph from "../Graph";
import {connect} from "react-redux";
import Cookies from "js-cookie";

class Panel extends React.Component {

    render() {
        return (
            <Router>
                <div className="column is-2">
                    <aside className="menu  is-medium">
                        <ul className="menu-list">
                            <h2 className='title'>
                                <Link to="/"><i className="fab fa-phoenix-squadron"> </i> APBot</Link>
                            </h2>
                            <li>
                                <Link to="/sends">Рассылка</Link>
                            </li>
                            <li>
                                <Link to="/users">Пользователи</Link>
                            </li>
                            <li>
                                <Link to="/graph">Расписание</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={this.props.onExit}>Выход</Link>
                            </li>
                        </ul>
                    </aside>
                </div>

                <Route path="/" exact component={Sends}/>
                <Route path="/users" component={Users} />
                <Route path="/sends" component={Sends} />
                <Route path="/graph" component={Graph} />
            </Router>
        );
    }
}

export default connect(
    state => ({

    }),
    dispatch => ({
        onExit: () => {
            Cookies.remove('login');
            dispatch({type: 'LOGIN_REFRESH', login : false});
        },
    })
)(Panel);
