const initialState = {
    isOpen: false,
    id: -1,
    chat_id: '',
    user_name: '',
    title: ''
};

export default function UserPopupState(state = initialState, action) {
    switch (action.type) {
        case 'USER_POPUP_EDIT':
            return Object.assign({}, state, {
                isOpen: true,
                id: action.id,
                chat_id: action.chat_id,
                user_name: action.user_name,
                title: action.title
            });
        case 'USER_POPUP_HIDE':
            return Object.assign({}, state, {
                isOpen: false,
                chat_id: '',
                user_name: '',
                title: '',
            });
        case 'USER_POPUP_TYPING_CHAT':
            return Object.assign({}, state, {
                chat_id: action.chat_id,
            });
        case 'USER_POPUP_TYPING_NAME':
            return Object.assign({}, state, {
                user_name: action.user_name,
            });
        default:
            return state
    }
}