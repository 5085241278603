import React, {Component} from 'react';
import {connect} from "react-redux";

class UserList extends Component {

    componentDidMount() {
        fetch('/data/groupTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSet(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {
        const items = this.props.sl.data;
        const sel = this.props.sl.sel;

        return (
            <div className="select">
                <select key='selUser' className="select" id='userGroup'>
                    {
                        items.map(item => {
                            return <option selected={item.title === sel} key={item.id} value={item.id}>{item.title}</option>
                        })
                    }
                </select>
            </div>
        );
    }
}

export default connect(
    state => ({
        sl: state.UserList
    }),
    dispatch => ({
        onSet: (data) => {
            dispatch({type: 'USER_LIST_REFRESH', data: data})
        }
    })
)(UserList);