import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from "jquery";

class GraphPopupBell extends Component {

    componentDidMount() {
        fetch('/data/bellTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSet(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {
        const style = this.props.sb.isOpen ? 'modal is-active' : 'modal';
        const data = Object.values(this.props.sb.data);

        return (
            <div className={style}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Расписание звонков</p>
                    </header>
                    <section className="modal-card-body">
                        {
                            data.map((d) =>
                                <div className="field is-horizontal" key={d.id}>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <input key={'s' + d.id} type="text" id={'itemS' + d.id}
                                                       defaultValue={d.start} className="input"/>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <input key={'e' + d.id} type="text" id={'itemE' + d.id}
                                                       defaultValue={d.end} className="input"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </section>
                    <footer className="modal-card-foot field is-grouped is-grouped-centered">
                        <div className="control">
                            <button onClick={this.props.onSave} className="button is-primary">Сохранить</button>
                        </div>
                        <div className="control">
                            <button onClick={this.props.onHide} className="button">Отмена</button>
                        </div>
                    </footer>
                </div>
            </div>

        );
    }
}

export default connect(
    state => ({
        sb: state.BellTableBody,
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'BELL_BODY_OPEN', isOpen: false})
        },
        onSet: (data) => {
            dispatch({type: 'BELL_BODY_SET', data: data});
        },
        onSave: () => {
            let items = [];

            for (let i = 1; i <= 7; i++) {
                const start = $('#itemS' + i).val();
                const end = $('#itemE' + i).val();

                items[i] = {start: start, end: end};
            }

            fetch('/data/bellDB.php', {
                method: 'post',
                body: JSON.stringify({
                    items: items,
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'BELL_BODY_SET', data: json});
            }).catch(function (c) {
                console.log(c);
            });

            dispatch({type: 'BELL_BODY_OPEN', isOpen: false})
        }
    })
)(GraphPopupBell);