const initialState = {
    isOpen: false,
    edit: -1,
    name : '',
};

export default function TeacherPopupState(state = initialState, action) {
    switch (action.type) {
        case 'GRAPH_POPUP_DO':
            return Object.assign({}, state, {
                isOpen: action.isOpen,
            });
        case 'GRAPH_POPUP_EDIT':
            return Object.assign({}, state, {
                edit: action.edit,
            });
        case 'GRAPH_POPUP_EDIT_TEXT':
            return Object.assign({}, state, {
                id: action.id,
                name: action.name,
            });
        default:
            return state
    }
}