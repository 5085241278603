const initialState = {
    mo : { items : {}},
    tu : { items : {}},
    we : { items : {}},
    th : { items : {}},
    fr : { items : {}},
    sa : { items : {}},
};


export default function GraphDayCheckState(state = initialState, action) {
    switch (action.type) {
        case 'GRAPH_DAY_SET':
            return Object.assign({}, state,  action.state);
        case 'GRAPH_DAY_CHECK':
            //return  Object.assign({}, state, { [action.we] : { items : {  [action.n] : {  check : action.check}}}});
            return  Object.assign({}, state, state[action.we]['items'][action.n]['check'] = action.check);
        default:
            return state
    }
}