import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';

class GraphPopupProcess extends Component {

    componentDidMount() {
        fetch('/data/graphProcess/graph.png?' + (Math.random() * 10000) ).then(function (response) {
            return response.blob();
        }).then(function (myBlob) {
            const objectURL = URL.createObjectURL(myBlob);
            this.props.onSet(objectURL);
        }.bind(this));
    }

    render() {
        const style = this.props.sp.isOpen ? 'modal is-active' : 'modal';
        const img = this.props.sp.data;

        return (
            <div className={style}>
                <div className="modal-background"> </div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">График учебного процесса</p>
                    </header>
                    <section className="modal-card-body">
                        <figure className="image">
                            <img key={img} src={img} alt="График учебного процесса"/>
                        </figure>
                    </section>
                    <footer className="modal-card-foot field is-grouped is-grouped-centered">
                        <div className="control">
                            <div className="file is-info">
                                <label className="file-label">
                                    <input className="file-input" type="file" id="graphProcess"/>
                                    <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload"> </i>
                                            </span>
                                            <span className="file-label">
                                                Выберите файл
                                            </span>
                                        </span>
                                </label>
                            </div>
                        </div>
                        <div className="control">
                            <button className="button is-primary" onClick={this.props.onSave}>Сохранить</button>
                        </div>
                        <div className="control">
                            <button type="button" className="button is-light" onClick={this.props.onHide}>Закрыть
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        sp: state.ProcessBody,
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'PROCESS_BODY_OPEN', isOpen: false})
        },
        onSet: (data) => {
            dispatch({type: 'PROCESS_BODY_IMG', data: data});
        },
        onSave: async () => {

            const f = $('#graphProcess')[0];

            const file = f.files[0];

            if (typeof file == 'undefined')
                return;

            const fd = new FormData();

            fd.append('graphProcess', file, file.name);

            await fetch('/data/graphProcess.php', {
                method: 'post',
                body: fd,
            }).then(function (response) {
                return response.text();
            }).then(function (json) {
            }).catch(function (c) {
                console.log(c);
            });

            await fetch('/data/graphProcess/graph.png?' + (Math.random() * 10000) ).then(function (response) {
                return response.blob();
            }).then(function (myBlob) {
                const objectURL = URL.createObjectURL(myBlob);
                dispatch({type: 'PROCESS_BODY_IMG', data: objectURL});
            });
        },
    })
)(GraphPopupProcess);