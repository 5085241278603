import React from 'react';
import GroupPopup from "./GroupPopup";
import GroupPopupDel from "./GroupPopupDel";
import UserPopup from "./UserPopup";
import UserPopupDel from "./UserPopupDel";

class Popup extends React.Component {
    render() {
        return (
        <div>
            <GroupPopup/>
            <GroupPopupDel/>
            <UserPopup/>
            <UserPopupDel/>
        </div>
        )
    }
}

export default Popup;