import React from 'react';
import SendUsers from "./SendUsers";
import SendForm from "./SendForm";
import {connect} from "react-redux";
import SendFileList from "./SendFileList";
import $ from "jquery";

class Send extends React.Component {
    render() {
        return (
            <form id="formSend" onSubmit={(e) => this.props.onSend(e, this.props.fl)}>

                <div className="columns">
                    <div className="column is-6">
                        <SendForm/>
                    </div>
                    <div className="column is-6">
                        <SendFileList/>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-12">
                        <SendUsers/>
                    </div>
                </div>
            </form>
        )
    }
}

export default connect(
    state => ({
        fl: state.SendFiles
    }),
    dispatch => ({
        onSend: (e, files) => {

            e.preventDefault();
            let val = e.target;

            $("#btnSend").addClass("is-loading");

            const fd = new FormData();

            //Фото/Видео
            for (let i = 0; i < files.IV.length; i++) {
                fd.append('sendIV[]', files.IV[i], files.IV[i].name);
            }

            //Документы
            for (let i = 0; i < files.F.length; i++) {
                fd.append('sendF[]', files.F[i], files.F[i].name);
            }

            fd.append('text', val.textArea.value);
            fd.append('users', val.sendForm.value);
            fd.append('type', 'sendMessage');

            fetch('/bot/do.php', {
                method: 'post',
                body: fd,
            }).then(function (response) {
                return response.text();
            }).then(function (json) {
                $("#btnSend").removeClass("is-loading");
                dispatch({type : 'SEND_FILES_LOADED', loaded : true});

                dispatch({type : 'SEND_FILES_RESET'});
                $("#formSend")[0].reset();
            }).catch(function (c) {
                console.log(c);
            });
        },
    })
)(Send);