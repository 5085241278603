import React from 'react';
import {connect} from 'react-redux';
import ReactTable from "react-table";

import edit from '../img/edit.png';
import del from '../img/del.png';

class UserTable extends React.Component {

    componentDidMount() {
        fetch('/data/userTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSetTable(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {

        const data = this.props.d;
        const columns = [{
            Header: 'ID-Чата',
            accessor: 'chat_id', // String-based value accessors!
            minWidth: 120,
        }, {
            Header: 'Имя пользователя',
            accessor: 'user_name',
            minWidth: 120,
        }, {
            Header: 'Группа',
            accessor: 'title',
            minWidth: 120,
        }, {
            Header: ' ',
            accessor: ' ',
            minWidth: 50,
            sortable: false,
            filterable: false,
            Cell: props => <img src={edit} alt="Редактировать" style={{width: '27px'}}
                                onClick={() => this.props.onShowEdit(props.original.id, props.original.chat_id,
                                    props.original.user_name, props.original.title)}/> // Custom cell components!
        }, {
            Header: ' ',
            accessor: ' ',
            minWidth: 50,
            sortable: false,
            filterable: false,
            Cell: props => <img src={del} alt="Удалить" style={{width: '27px'}}
                                onClick={() => this.props.onShowDel(props.original.id)}/> // Custom cell components!
        }];

        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Пользователи
                    </p>
                </header>
                <ReactTable className="table table-hover" id="userTable"
                            filterable
                            data={data}
                            columns={columns}
                            showPagination={false}
                            pageSize={data.length}
                            resizable={false}
                            style={{
                                textAlign: 'center',
                            }}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isOpen: state.UserPopup,
        d: state.UserTable
    }),
    dispatch => ({
        onSetTable: (data) => {
            dispatch({type: 'USER_TABLE_SET', data: data})
        },
        onShowEdit: (id, chat_id, user_name, title) => {
            dispatch({type: 'USER_LIST_SEL', sel: title});

            dispatch({
                type: 'USER_POPUP_EDIT',
                send: 'edit',
                id: id,
                chat_id: chat_id,
                user_name: user_name,
                title: title
            });
        },
        onShowDel: (id) => {
            dispatch({type: 'USER_POPUP_DEL_SHOW', id: id})
        }
    })
)(UserTable);