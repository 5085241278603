import React, {Component} from 'react';
import {connect} from "react-redux";
import $ from "jquery";

class GroupList extends Component {

    async componentDidMount() {
        await fetch('/data/groupTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSet(json.map(d => ({id: d.id, title: d.title})));
        }.bind(this)).catch(function (c) {
            console.log(c);
        });

        this.props.onSelGroup();
    }

    render() {
        const items = this.props.sl.data;

        return (
            <div className="select">
                <select onChange={this.props.onSelGroup} key='selUser' id="graphGroup" name="userGroup"
                        className="select" style={{border : 'none'}}>
                    {
                        items.map(item => {
                            return <option key={item.id} value={item.id}>{item.title}</option>
                        })
                    }
                </select>
            </div>
        );
    }
}

export default connect(
    state => ({
        sl: state.UserList
    }),
    dispatch => ({
        onSet: (data) => {
            dispatch({type: 'USER_LIST_REFRESH', data: data})
        },
        onSelGroup: () => {
            $('#formDays')[0].reset();

            fetch('/data/graphTable.php', {
                method: 'post',
                body: JSON.stringify({
                    id: $('#graphGroup').val(),
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                const days = ['mo', 'tu', 'we', 'th', 'fr', 'sa'];
                let res = {};

                days.map(function (d) {
                        res[d] = JSON.parse(json[0][d]);

                        return 0;
                    }
                );
                console.log(res);
                dispatch({type: 'GRAPH_DAY_SET', state: res});
            }).catch(function (c) {
                console.log(c);
            });
        }
    })
)(GroupList);