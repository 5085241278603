import React from 'react';
import {connect} from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

class SendUsers extends React.Component {

    componentDidMount() {
        fetch('/data/groupListUser.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onLoad(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {
        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Список пользователей
                    </p>
                </header>
                <div className="card-content">
                    <CheckboxTree
                        nodes={[this.props.ud.data]}
                        checked={this.props.sd.checked}
                        expanded={this.props.sd.expanded}
                        onCheck={checked => this.props.onChecked(checked)}
                        onExpand={expanded => this.props.onExpanded(expanded)}
                        name='sendForm'
                        id='sendUserForm'
                        nameAsArray={false}
                        icons={{
                            check: <span className="fas fa-check-square"/>,
                            uncheck: <span className="far fa-square"/>,
                            halfCheck: <span className="far fa-minus-square" />,
                            expandClose: <span className="fas fa-angle-right" />,
                            expandOpen: <span className="fas fa-angle-down" />,
                            collapseAll: <span className="far fa-folder-open" />,
                            parentClose: <span className="far fa-folder" />,
                            parentOpen: <span className="far fa-folder-open" />,
                            leaf: <span className="far fa-file"/>,
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        ud: state.SendListUser,
        sd: state.SendSelect
    }),
    dispatch => ({
        onLoad: (data) => {
            dispatch({type: 'SEND_LIST_USER_LOAD', data: data});
        },
        onChecked: (checked) => {
            dispatch({type: 'SEND_SELECT_CHECKED', checked: checked});
        },
        onExpanded: (expanded) => {
            dispatch({type: 'SEND_SELECT_EXPANDED', expanded: expanded});
        },
    })
)(SendUsers);