const initialState = {
    isOpen: false,
    data: {},
};

export default function ProcessBodyState(state = initialState, action) {
    switch (action.type) {
        case 'PROCESS_BODY_OPEN':
            return Object.assign({}, state, {
                isOpen: action.isOpen
            });
        case 'PROCESS_BODY_IMG':
            return Object.assign({}, state, {
                data: action.data
            });
        default:
            return state
    }
}