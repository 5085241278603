const initialState = {
    isOpen: false,
    id: -1,
    send: 'add',
    title: ''
};

export default function GroupPopupState(state = initialState, action) {
    switch (action.type) {
        case 'GROUP_POPUP_ADD':
            return Object.assign({}, state, {
                isOpen: true,
                send: 'add',
                title: ''
            });
        case 'GROUP_POPUP_EDIT':
            return Object.assign({}, state, {
                isOpen: true,
                id: action.id,
                send: action.send,
                title: action.title
            });
        case 'GROUP_POPUP_HIDE':
            return Object.assign({}, state, {
                isOpen: false,
            });
        case 'GROUP_POPUP_TYPING':
            return Object.assign({}, state, {
                title: action.title,
            });
        default:
            return state
    }
}