import React from 'react';
import {connect} from 'react-redux';

class SendForm extends React.Component {

    render() {

        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Форма отправки
                    </p>
                </header>
                <div className="card-content">
                    <div className="field">
                        <label className="label">Текст</label>
                        <div className="control">
                            <textarea className="textarea textAreaStyle" placeholder="Текст записи" name='textArea' ></textarea>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field is-narrow">
                                <label className="label">Фото/Видео</label>
                                <div className="file is-info">
                                    <label className="file-label">
                                        <input type="file" className="file-input" multiple
                                               accept=".jpg, .jpeg, .png, .bmp, .mp4, .flv"
                                               onChange={this.props.onLoadIV}/>
                                        <span className="file-cta">
                                      <span className="file-icon">
                                          <i className="fas fa-upload"> </i>
                                      </span>
                                      <span className="file-label">
                                          Выбрать файл...
                                      </span>
                                    </span>
                                    </label>
                                </div>
                            </div>
                            <div className="field is-narrow">
                                <label className="label">Документы</label>
                                <div className="file is-info">
                                    <label className="file-label">
                                        <input type="file" className="file-input" multiple
                                               onChange={this.props.onLoadF}/>
                                        <span className="file-cta">
                                      <span className="file-icon">
                                          <i className="fas fa-upload"> </i>
                                      </span>
                                      <span className="file-label">
                                          Выбрать файл...
                                      </span>
                                    </span>
                                    </label>
                                </div>
                            </div>
                            <div className="field is-narrow">
                                <label className="label">Действие</label>
                                <button id="btnSend" type="submit" className="button is-primary">Отправить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        fl: state.SendFiles
    }),
    dispatch => ({
        onLoadIV: (e) => {
            const f = Array.from(e.target.files);
            e.target.value = "";
            dispatch({type: 'SEND_FILES_ADD_IV', IV: f});
        },
        onLoadF: (e) => {
            const f = Array.from(e.target.files);
            e.target.value = "";
            dispatch({type: 'SEND_FILES_ADD_F', F: f});
        },
    })
)(SendForm);