import React from 'react';
import {connect} from 'react-redux';

class SendFileList extends React.Component {

    render() {

        const files = this.props.fl;

        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Список файлов
                    </p>
                </header>
                <div className="card-content fileList">
                    <div className="content">
                        {
                            Object.keys(files).map(function (key) {

                                if (key === 'loaded')
                                    return;

                                const t = files[key];

                                return t.map((file, ind) =>
                                    <div key={key}>
                                        <article key={key} className="media is-marginless">
                                            <div className="media-left">
                                                <figure className="image is-64x64 is-marginless">
                                                    <i className="fas fa-file fa-3x"> </i>
                                                </figure>
                                            </div>
                                            <div className="media-content monoText" key={file.size}
                                                 style={{paddingRight: '10px'}}>
                                                <p className='monoText'>{file.name}</p>
                                                <button className="delete" type="button"
                                                        onClick={() => this.props.onDel(key, ind)}>
                                                </button>
                                                <p className='is-pulled-right'>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                                            </div>
                                        </article>
                                        <hr/>
                                    </div>
                                )
                            }.bind(this))
                        }
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(
    state => ({
        fl: state.SendFiles
    }),
    dispatch => ({
        onDel: (key, pos) => {
            dispatch({type: 'SEND_FILES_DEL', key: key, pos: pos});
        },
    })
)(SendFileList);