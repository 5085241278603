const initialState = {
    isOpen: false,
    id: -1
};

export default function GroupPopupDelState(state = initialState, action) {
    switch (action.type) {
        case 'GROUP_POPUP_DEL_SHOW':
            return Object.assign({}, state, {
                isOpen: true,
                id: action.id
            });
        case 'GROUP_POPUP_DEL_HIDE':
            return Object.assign({}, state, {
                isOpen: false
            });
        default:
            return state
    }
}