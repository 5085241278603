import React from 'react';

import Send from "./Send";
import SendPopup from "./Send/SendPopup";

class Sends extends React.Component {
    render() {
        return (
            <div className="column is-10">
                <Send/>
                <SendPopup/>
            </div>
        )
    }
}

export default Sends;