const initialState = {
    isOpen: false,
    data: {0 : {id: "", start: "", end: ""}}
};

export default function BellTableBodyState(state = initialState, action) {
    switch (action.type) {
        case 'BELL_BODY_SET':
            return Object.assign({}, state, {
                data: action.data
            });
        case 'BELL_BODY_OPEN':
            return Object.assign({}, state, {
                isOpen: action.isOpen
            });
        default:
            return state
    }
}