import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';

class GroupPopupDel extends Component {

    render() {
        const style = this.props.st.isOpen ? 'modal is-active' : 'modal';
        return (
            <div className={style}>
                <div className="modal-background"> </div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Удаление</p>
                    </header>
                    <section className="modal-card-body">
                        <input id="groupDelId" className="input" type="hidden" value={this.props.st.id}/>
                        <h2>Вы дествительно хотите удалить?</h2>
                    </section>
                    <footer className="modal-card-foot field is-grouped is-grouped-centered">
                        <div className="control">
                            <button className="button is-danger" onClick={this.props.onSave}>Удалить</button>
                        </div>
                        <div className="control">
                            <button className="button is-light" onClick={this.props.onHide}>Отмена</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        st: state.GroupPopupDel
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'GROUP_POPUP_DEL_HIDE'})
        },
        onSave: () => {
            fetch('/data/groupDB.php', {
                method: 'post',
                body: JSON.stringify({
                    id: $('#groupDelId').val(),
                    type: 'del'
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'GROUP_TABLE_SET', data: json})
            }).catch(function (c) {
                console.log(c);
            });

            dispatch({type: 'GROUP_POPUP_DEL_HIDE'})
        }
    })
)(GroupPopupDel);