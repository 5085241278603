import React from 'react';
import {connect} from 'react-redux';
import GraphTableDay from "./GraphTableDay";

class GraphTable extends React.Component {

    componentDidMount() {

        fetch('/data/teacherTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSetTeacher(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        });
    }

    render() {
        // mo tu we th fr sa

        const days = ['mo','tu','we','th','fr','sa'];

        return (
            <div className="columns">
                <div className="column is-12">
                {
                    days.map( (d) =>
                        <GraphTableDay key={d} week={d}/>
                    )
                }
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        isOpen: state.GroupPopup,
        d: state.GraphTableBody
    }),
    dispatch => ({
        onSetTeacher: (data) => {
            //const d = Object.assign({ data.length : {id: '0', name : 'выберите'} } ,data);
            data.unshift({id: 0, name: 'Нет'});

            dispatch({type: 'TEACHER_LIST_REFRESH', data: data})
        },

    })
)(GraphTable);