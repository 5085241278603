const initialState = {
    sel : '',
    data : []
};


export default function UserList(state = initialState, action) {
    switch (action.type) {
        case 'USER_LIST_REFRESH':
            return  Object.assign({}, state, {
                data : action.data
            });
        case 'USER_LIST_SEL':
            return  Object.assign({}, state, {
                sel : action.sel
            });
        default:
            return state
    }
}