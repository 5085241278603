import React, {Component} from 'react';
import {connect} from "react-redux";

class TeacherList extends Component {

    render() {
        const items = this.props.sl.data;
        const sel = this.props.sel;

        return (
            <div className={"select fnt is-small " + (!this.props.h ? 'is-hidden' : '')}>
                <select id={this.props.name} key='selTeacher'>
                    {
                        items.map(item => {
                            return <option selected={item.id === sel || item.id === 0} key={item.id} value={item.id}>{item.name}</option>
                        })
                    }
                </select>
            </div>
        );
    }
}

export default connect(
    state => ({
        sl: state.TeacherList
    }),
    dispatch => ({
        onSet: (data) => {
            dispatch({type: 'TEACHER_LIST_REFRESH', data: data})
        }
    })
)(TeacherList);