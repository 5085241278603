import React from 'react';
import Cookies from 'js-cookie';
import {connect} from "react-redux";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {

        e.preventDefault();

        fetch('/data/login.php', {
            method: 'post',
            body: JSON.stringify({
                login: e.target.login.value,
                pass: e.target.pass.value,
            })
        }).then(function (response) {
            return response.text();
        }).then(function (json) {
            if (json === '1')
                this.props.onLogin();
            else
                this.props.onError();
        }.bind(this)).catch(function (c) {
            console.log(c);
        });

    }

    render() {

        const error = this.props.sl.error;

        return (
            <div className='container'>
                <div className="columns">
                    <div className="column"> </div>
                    <div className="column is-one-third login">
                        <div className='box'>
                            <h1 className='title has-text-centered'>Авторизация</h1>
                            {/* eslint-disable-next-line no-script-url */}
                            <form onSubmit={this.handleClick} autoComplete='on'>
                                {error ? <p>Неверный логин или пароль</p> : <p> </p>}
                                <div className="field">
                                    <label htmlFor="login" className="label">Логин</label>
                                    <div className="control">
                                        <input id='login' className="input" type="text" placeholder="user"/>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="pass" className="label">Пароль</label>
                                    <div className="control">
                                        <input id='pass' className="input" type="password" placeholder="password"/>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <button className="button is-info is-fullwidth">Войти</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="column"> </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        sl : state.Login,
    }),
    dispatch => ({
        onLogin: () => {
            Cookies.set('login', 'true', { expires: 7 });
            dispatch({type: 'LOGIN_REFRESH'});
        },
        onError: () => {
            dispatch({type: 'LOGIN_ERROR'});
        },
    })
)(Login);