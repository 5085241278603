const initialState = {
    loaded : false,
    IV: [],
    F: [],
};


export default function SendFilesState(state = initialState, action) {
    switch (action.type) {
        case 'SEND_FILES_ADD_IV':
            return Object.assign({}, state, {
                IV: [...state.IV,...action.IV]
            });
        case 'SEND_FILES_ADD_F':
            return Object.assign({}, state, {
                F: [...state.F,...action.F]
            });
        case 'SEND_FILES_RESET':
            return Object.assign({}, state, {
                F: [],
                IV: []
            });
        case 'SEND_FILES_DEL':
            let res = Object.assign({}, state);
            res[action.key].splice(action.pos, 1);
            return res;
        case 'SEND_FILES_LOADED':
            return Object.assign({}, state, {
                loaded: action.loaded
            });
        default:
            return state
    }
}