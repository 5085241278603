import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserList from "../User/UserList";
import $ from "jquery";

class UserPopup extends Component {

    render() {
        const style = this.props.st.isOpen ? 'modal is-active' : 'modal';

        return (
            <div className={style}>
                <div className="modal-background"> </div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Редактирование</p>
                    </header>
                    <form action="javascript:void(0);">
                        <section className="modal-card-body">
                            <input id="userId" type="hidden" value={this.props.st.id}/>
                            <label className="label">ID-Чата</label>
                            <div className="field">
                                <div className="control">
                                    <input id='userChatId' value={this.props.st.chat_id} onChange={this.props.onTypingChat}
                                           type="text" className="input is-unselectable" placeholder="123456789"
                                           pattern="[0-9]{9}" required/>
                                </div>
                            </div>
                            <label className="label">Имя пользователя</label>
                            <div className="field">
                                <div className="control">
                                    <input id='userName' value={this.props.st.user_name} onChange={this.props.onTypingName}
                                           type="text" className="input is-unselectable" placeholder="Пользователь" required/>
                                </div>
                            </div>
                            <label className="label">Группа</label>
                            <div className="field">
                                <div className="control">
                                    <UserList key='UserList'/>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot field is-grouped is-grouped-centered">
                            <div className="control">
                                <button className="button is-primary" onClick={this.props.onSave}>Принять</button>
                            </div>
                            <div className="control">
                                <button className="button is-light" onClick={this.props.onHide}>Отмена</button>
                            </div>
                        </footer>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        st: state.UserPopup
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'USER_POPUP_HIDE'})
        },
        onTypingChat: (e) => {
            dispatch({type: 'USER_POPUP_TYPING_CHAT', chat_id: e.target.value});
        },
        onTypingName: (e) => {
            dispatch({type: 'USER_POPUP_TYPING_NAME', user_name: e.target.value});
        },
        onSave: () => {

            const id = $('#userId')[0];
            const chatId = $('#userChatId')[0];
            const userName = $('#userName')[0];
            const userGroup = $('#userGroup')[0];

            if (!chatId.checkValidity() || !userName.checkValidity())
                return;

            fetch('/data/userDB.php', {
                method: 'post',
                body: JSON.stringify({
                    id: id.value,
                    type: 'edit',
                    chatId: chatId.value,
                    userName: userName.value,
                    userGroup: userGroup.value,
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'USER_TABLE_SET', data: json})
            }).catch(function (c) {
                console.log(c);
            });

            dispatch({type: 'USER_POPUP_HIDE'})
        }
    })
)(UserPopup);