export default function LoginState(state = { login : false , error : false}, action) {
    switch (action.type) {
        case 'LOGIN_REFRESH':
            return Object.assign({}, state, {
                login: true,
                error: false,
            });
        case 'LOGIN_ERROR':
            return Object.assign({}, state, {
                login: false,
                error: true,
            });
        default:
            return state
    }
}