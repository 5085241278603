const initialState = {
    isOpen: false,
};

export default function BurgerState(state = initialState, action) {
    switch (action.type) {
        case 'BURGER_ACTION':
            return Object.assign({}, state, {
                isOpen: action.isOpen
            });
        default:
            return state
    }
}