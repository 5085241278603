import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';

class GroupPopup extends Component {

    render() {
        const style = this.props.st.isOpen ? 'modal is-active' : 'modal';
        const type = this.props.st.send;

        return (
            <div className={style}>
                <div className="modal-background"> </div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{type === 'edit' ? 'Редактирование' : 'Добавление'}</p>
                    </header>
                    <form action="javascript:void(0);">
                        <section className="modal-card-body">
                            <input id='groupId' type="hidden" value={this.props.st.id}/>
                            <input id='groupType' type="hidden" value={this.props.st.send}/>
                            <label className="label">Название</label>
                            <div className="field">
                                <div className="control">
                                    <input id='groupTitle' value={this.props.st.title} onChange={this.props.onTyping}
                                           type="text" className="input" placeholder="П-11"
                                           minLength='3' maxLength='20' required/>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot field is-grouped is-grouped-centered">
                            <div className="control">
                                <button className="button is-primary" onClick={this.props.onSave}>Принять</button>
                            </div>
                            <div className="control">
                                <button className="button is-light" onClick={this.props.onHide}>Отмена</button>
                            </div>
                        </footer>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        st: state.GroupPopup
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'GROUP_POPUP_HIDE'})
        },
        onTyping: (e) => {
            dispatch({type: 'GROUP_POPUP_TYPING', title: e.target.value.toUpperCase()});
        },
        onSave: async () => {

            const id = $('#groupId')[0];
            const type = $('#groupType')[0];
            const title = $('#groupTitle')[0];

            if (!title.checkValidity())
                return;

            await fetch('/data/groupDB.php', {
                method: 'post',
                body: JSON.stringify({
                    id: id.value,
                    type: type.value,
                    title: title.value,
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'GROUP_TABLE_SET', data: json});
                dispatch({type: 'USER_LIST_REFRESH', data: json});
            }).catch(function (c) {
                console.log(c);
            });

            await fetch('/data/userTable.php', {
                method: 'post'
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'USER_TABLE_BODY_SET', data: json});
            }).catch(function (c) {
                console.log(c);
            });

            dispatch({type: 'GROUP_POPUP_HIDE'})
        }
    })
)(GroupPopup);