import React from 'react';
import Cookies from 'js-cookie';

import Panel from "./Panel";
import Login from "./Login";
import {connect} from "react-redux";

class App extends React.Component {

	render() {
		//Cookies.remove('login');
		const key = this.props.sl.login;
		const login = Cookies.get('login');

		if (login)
			return (
				<section className="section">
					<div key={key} className="columns">
						<Panel/>
					</div>
				</section>
			);
		else
			return (
				<section className="hero is-fullheight">
					<div key={key} className="hero-body">
						<Login/>
					</div>
				</section>
			);
	}
}

export default connect(
	state => ({
		sl: state.Login,
	}),
	dispatch => ({

	})
)(App);
