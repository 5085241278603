import React from 'react';
import TeacherList from "./TeacherList";
import {connect} from "react-redux";

class GraphTableDay extends React.Component {

    render() {

        const we = this.props.week;
        const day = this.props.ds;

        if (typeof day[we].items === 'undefined')
            day[we] = {items: {}};

        const num = [1, 2, 3, 4, 5, 6, 7];

        const daysName = {
            mo: 'Понедельник',
            tu: 'Вторник',
            we: 'Среда',
            th: 'Четверг',
            fr: 'Пятница',
            sa: 'Суббота',
        };

        return (
            <div className="box">
                <label className="label has-text-centered">{daysName[we]}</label>
                <table className="table">
                    <tbody>
                    {
                        num.map(function (n) {
                            const d = typeof day[we].items[n] === 'undefined' ? {} : day[we].items[n];

                            return (
                                <tr key={n}>
                                    <td className="is-narrow">{n}</td>

                                    <td>
                                        <div className="field is-horizontal">
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <input className='input is-primary is-small fnt'
                                                               placeholder='Название'
                                                               id={'name' + we + n} defaultValue={d.item} type="text"/>
                                                    </div>
                                                </div>

                                                <div className="field is-narrow">
                                                    <div className="control">
                                                        <TeacherList sel={d.teacher} name={'teacher' + we + n}
                                                                     h={true}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field is-horizontal">
                                            <div className="field-body">
                                                <div className="field">
                                                    <div className="control">
                                                        <input
                                                            className={'input is-primary is-small fnt ' + (!d.check ? 'is-hidden' : '')}
                                                            placeholder='Название' id={'name2' + we + n}
                                                            defaultValue={d.item2}
                                                            type="text"/>
                                                    </div>
                                                </div>
                                                <div className="field is-narrow">
                                                    <div className="control">
                                                        <TeacherList sel={d.teacher2} name={'teacher2' + we + n}
                                                                     h={d.check}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td className="is-narrow">
                                        <label className="checkbox">
                                            <input onClick={(e) => this.props.onCheck(e.target.checked, we, n)}
                                                   defaultChecked={d.check} type="checkbox" id={'check' + we + n}/>
                                        </label>
                                    </td>
                                </tr>
                            )
                        }.bind(this))
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default connect(
    state => ({
        ds: state.GraphDayCheck,
    }),
    dispatch => ({
        onCheck: (check, we, n) => {
            dispatch({type: 'GRAPH_DAY_CHECK', check: check, we: we, n: n});
        },
    })
)(GraphTableDay);