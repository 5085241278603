import React from 'react';
import GraphTable from "./GraphTable";
import {connect} from "react-redux";
import $ from 'jquery'
import GroupList from "./GroupList";

class Graph extends React.Component {
    render() {
        const openB = this.props.sb.isOpen;

        return (
            <div className="card">
                <header className="card-header">

                    <nav className="navbar" role="navigation" aria-label="main navigation"
                         style={{width: '-webkit-fill-available'}}>
                        <div className="navbar-brand">
                            <p className="card-header-title">
                                Расписание
                            </p>

                            <div role="button" className={"navbar-burger burger " + (openB ? 'is-active' : '' )} aria-label="menu"
                                 aria-expanded="false" onClick={ () => this.props.onShowBurger(!openB) }>
                                <span aria-hidden="true"> </span>
                                <span aria-hidden="true"> </span>
                                <span aria-hidden="true"> </span>
                            </div>
                        </div>

                        <div className={"navbar-menu " + (openB ? 'is-active' : '' )}>
                            <div className="navbar-start">
                                <div className="navbar-item">
                                    <button className='button' onClick={this.props.onShowTeacher}>Преподаватели</button>
                                </div>

                                <div className="navbar-item">
                                    <button className='button' onClick={this.props.onShowBell}>Расписание звонков
                                    </button>
                                </div>

                                <div className="navbar-item">
                                    <button className='button' onClick={this.props.onShowProcess}>График учебного
                                        процесса
                                    </button>
                                </div>
                            </div>

                            <div className="navbar-end">
                                <div className="navbar-item">
                                    <GroupList/>
                                </div>

                                <div className="navbar-item">
                                    <button className='button' onClick={this.props.onSave}>Сохранить</button>
                                </div>

                            </div>
                        </div>
                    </nav>

                </header>
                <div className="card-content">
                    <div className="content">
                        <form id='formDays'>
                            <GraphTable/>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        sb : state.Burger,
    }),
    dispatch => ({
        onShowTeacher: () => {
            dispatch({type: 'GRAPH_POPUP_DO', isOpen: true})
        },
        onShowBurger: (st) => {
            dispatch({type: 'BURGER_ACTION', isOpen: st})
        },
        onShowBell: () => {
            dispatch({type: 'BELL_BODY_OPEN', isOpen: true})
        },
        onShowProcess: () => {
            dispatch({type: 'PROCESS_BODY_OPEN', isOpen: true})
        },
        onSave: () => {

            let data = {
                mo : {},
                tu : {},
                we : {},
                th : {},
                fr : {},
                sa : {},
            };

            Object.keys(data).map(function(key) {

                let items = { items : {} };

                for (let i = 1; i <= 7; i++) {
                    const item = $('#name' + key + i).val();
                    const teacher = $('#teacher' + key + i).val();
                    const item2 = $('#name2' + key + i).val();
                    const teacher2 = $('#teacher2' + key + i).val();
                    const check = $('#check' + key + i)[0].checked;

                    if (check)
                        items['items'][i] = { item: item, teacher : teacher , item2 : item2, teacher2 : teacher2, check : check};
                    else
                        items['items'][i] = { item: item, teacher : teacher , item2 : '', teacher2 : 0, check : check};
                }

                data[key] = items;

            });

            fetch('/data/graphDB.php', {
                method: 'post',
                body: JSON.stringify({
                    data: data,
                    id: $('#graphGroup').val(),
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                const days = ['mo', 'tu', 'we', 'th', 'fr', 'sa'];
                let res = {};

                days.map(function (d) {
                        res[d] = JSON.parse(json[0][d]);

                        return 0;
                    }
                );
                console.log(res);
                dispatch({type: 'GRAPH_DAY_SET', state: res});
            }).catch(function (c) {
                console.log(c);
            });

        },
    })
)(Graph);