const initialState = {
    checked: [],
    expanded: [],
};


export default function SendSelectState(state = initialState, action) {
    switch (action.type) {
        case 'SEND_SELECT_CHECKED':
            return  Object.assign({}, state, {
                checked : action.checked
            });
        case 'SEND_SELECT_EXPANDED':
            return  Object.assign({}, state, {
                expanded : action.expanded
            });
        default:
            return state
    }
}