import React, {Component} from 'react';
import {connect} from 'react-redux';

class SendPopup extends Component {

    render() {
        const style = this.props.fl.loaded ? 'modal is-active' : 'modal';
        return (
            <div className={style}>
                <div className="modal-background"> </div>
                <div className="modal-card">
                    <article className="message is-info">
                        <div className="message-header">
                            <p>Состояние</p>
                            <button type="button" className="delete" aria-label="delete"
                                    onClick={this.props.onClose}> </button>
                        </div>
                        <div className="message-body">
                            Отправленно.
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        fl: state.SendFiles
    }),
    dispatch => ({
        onClose: () => {
            dispatch({type: 'SEND_FILES_LOADED', loaded: false})
        },
    })
)(SendPopup);