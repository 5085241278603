import React from 'react';

import Graph from "./GraphTable";

import GraphPopupTeacher from "./GraphTable/GraphPopupTeacher";
import GraphPopupBell from "./GraphTable/GraphPopupBell";
import GraphPopupProcess from "./GraphTable/GraphPopupProcess";

class GraphI extends React.Component {
    render() {
        return (
            <div className="column is-10">
                <Graph/>
                <GraphPopupTeacher/>
                <GraphPopupBell/>
                <GraphPopupProcess/>
            </div>
        )
    }
}

export default GraphI;