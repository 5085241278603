const initialState = {
    data: []
};


export default function TeacherListState(state = initialState, action) {
    switch (action.type) {
        case 'TEACHER_LIST_REFRESH':
            return Object.assign({}, state, {
                data: action.data
            });
        default:
            return state
    }
}