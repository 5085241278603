import React from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table'

import add from '../img/add.png';
import edit from '../img/edit.png';
import del from '../img/del.png';

class GroupTable extends React.Component {

    componentDidMount() {
        fetch('/data/groupTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSetTable(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {

        const data = this.props.d;

        const columns = [{
            Header: 'Название',
            accessor: 'title', // String-based value accessors!
            minWidth: 120,
        }, {
            Header: ' ',
            accessor: ' ',
            minWidth: 50,
            sortable: false,
            Cell: props => <a><img src={edit} alt="Редактировать" style={{width: '27px'}}
                                   onClick={() => this.props.onShowEdit(props.original.id, props.original.title)}/></a>
        }, {
            Header: ' ',
            accessor: ' ',
            minWidth: 50,
            sortable: false,
            Cell: props => <a><img src={del} alt="Удалить" style={{width: '27px'}}
                                    onClick={() => this.props.onShowDel(props.original.id)}/></a>
        }];

        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Группы
                    </p>
                    <a className="card-header-icon" aria-label="more options">
                        <img src={add} alt="Добавить" style={{width: '27px'}}
                             onClick={this.props.onShow}/>
                    </a>
                </header>
                <ReactTable className="table"
                            data={data}
                            columns={columns}
                            showPagination={false}
                            pageSize={data.length}
                            resizable={false}
                            style={{
                                textAlign: 'center',
                            }}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isOpen: state.GroupPopup,
        d: state.GroupTable
    }),
    dispatch => ({
        onShow: () => {
            dispatch({type: 'GROUP_POPUP_ADD'})
        },
        onSetTable: (data) => {
            dispatch({type: 'GROUP_TABLE_SET', data: data})
        },
        onShowEdit: (id, title) => {
            dispatch({type: 'GROUP_POPUP_EDIT', send: 'edit', id: id, title: title})
        },
        onShowDel: (id) => {
            dispatch({type: 'GROUP_POPUP_DEL_SHOW', id: id})
        }
    })
)(GroupTable);