import React, {Component} from 'react';
import {connect} from 'react-redux';

class GraphPopupTeacher extends Component {

    componentDidMount() {
        fetch('/data/teacherTable.php', {
            method: 'post',
        }).then(function (response) {
            return response.json();
        }).then(function (json) {
            this.props.onSetTable(json);
        }.bind(this)).catch(function (c) {
            console.log(c);
        })
    }

    render() {
        const style = this.props.st.isOpen ? 'modal is-active' : 'modal';

        const data = this.props.dt;

        return (
            <div className={style}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Преподаватели</p>
                    </header>
                    <section className="modal-card-body">
                        <div className="content">


                            {/* eslint-disable-next-line no-script-url */}
                            <form action="javascript:void(0);" id='teacherList'>
                                {
                                    Object.keys(data).map(function (k) {

                                        const edit = !(data[k].id === this.props.st.edit);
                                        const name = data[k].name;
                                        const id = data[k].id;

                                        if (id === 0)
                                            return (<div key={id}></div>);

                                        if (edit)
                                            return (
                                                <div key={id} className="field is-horizontal"
                                                     style={{marginBottom: '0.75rem'}}>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control">
                                                                <input key={id} defaultValue={name} type="text"
                                                                       readOnly={edit} className="input"/>
                                                            </div>
                                                        </div>
                                                        <div className="field is-narrow">
                                                            <div className="control">
                                                                <button className="button is-info"
                                                                        onClick={() => this.props.onEdit(id, name)}>Изменить
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="field is-narrow">
                                                            <div className="control">
                                                                <button className="button is-danger"
                                                                        onDoubleClick={() => this.props.onDelRow(id)}>Удалить
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        else
                                            return (
                                                <div key={id} className="field is-horizontal"
                                                     style={{marginBottom: '0.75rem'}}>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control">
                                                                <input key={id} defaultValue={name} type="text"
                                                                       className="input"
                                                                       readOnly={edit}
                                                                       onChange={(e) => this.props.onEditText(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="field is-narrow">
                                                            <div className="control">
                                                                <button className="button is-primary"
                                                                        onClick={() => this.props.onEditRow(id, this.props.st.name)}>Сохранить
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="field is-narrow">
                                                            <div className="control">
                                                                <button className="button is-danger"
                                                                        onDoubleClick={() => this.props.onDelRow(id)}>Удалить
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    }.bind(this))
                                }
                            </form>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <form action="" onSubmit={(e) => this.props.onAddRow(e)}
                              style={{width: '-webkit-fill-available'}}>
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input className="input" type="text" name="name"/>
                                        </div>
                                    </div>
                                    <div className="field is-narrow">
                                        <div className="control is-narrow">
                                            <input className="button is-primary" type="submit" value="Добавление"/>
                                        </div>
                                    </div>
                                    <div className="field is-narrow">
                                        <div className="control is-narrow">
                                            <button className="button" type="button"
                                                    onClick={this.props.onHide}>Закрыть
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </footer>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        st: state.TeacherPopup,
        dt: state.TeacherTableBody,
    }),
    dispatch => ({
        onHide: () => {
            dispatch({type: 'GRAPH_POPUP_DO', isOpen: false})
        },
        onSetTable: (data) => {
            dispatch({type: 'TEACHER_TABLE_BODY_SET', data: data})
        },
        onEdit: (edit, name) => {
            dispatch({type: 'GRAPH_POPUP_EDIT_TEXT', edit: edit, name: name});
            document.getElementById("teacherList").reset();
            dispatch({type: 'GRAPH_POPUP_EDIT', edit: edit})
        },
        onEditText: (name) => {
            dispatch({type: 'GRAPH_POPUP_EDIT_TEXT', name: name})
        },
        onAddRow: (e) => {

            e.preventDefault();

            fetch('/data/teacherDB.php', {
                method: 'post',
                body: JSON.stringify({
                    name: e.target.name.value,
                    type: 'add',
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'TEACHER_TABLE_BODY_SET', data: json});
                json.unshift({id: 0, name: 'Нет'});
                dispatch({type: 'TEACHER_LIST_REFRESH', data: json});
                dispatch({type: 'GRAPH_POPUP_EDIT', edit: -1})
            }).catch(function (c) {
                console.log(c);
            });

            e.target.name.value = '';
        },
        onEditRow: (id, name) => {
            fetch('/data/teacherDB.php', {
                method: 'post',
                body: JSON.stringify({
                    id: id,
                    name: name,
                    type: 'edit',
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'TEACHER_TABLE_BODY_SET', data: json});
                json.unshift({id: 0, name: 'Нет'});
                dispatch({type: 'TEACHER_LIST_REFRESH', data: json});
                dispatch({type: 'GRAPH_POPUP_EDIT', edit: -1})
            }).catch(function (c) {
                console.log(c);
            });
        },
        onDelRow: (id) => {
            fetch('/data/teacherDB.php', {
                method: 'post',
                body: JSON.stringify({
                    id: id,
                    type: 'del',
                })
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                dispatch({type: 'TEACHER_TABLE_BODY_SET', data: json});
                json.unshift({id: 0, name: 'Нет'});
                dispatch({type: 'TEACHER_LIST_REFRESH', data: json});
            }).catch(function (c) {
                console.log(c);
            });
        },
    })
)(GraphPopupTeacher);