import { combineReducers, createStore} from 'redux'

import GroupTable from './GroupTableState'
import UserTable from './UserTableState'

import GroupPopup from './GroupPopupState'
import GroupPopupDel from './GroupPopupDelState'
import UserPopup from './UserPopupState'
import UserList from './UserList'
import UserPopupDel from './UserPopupDelState'

import SendFiles from "./SendFilesState";
import SendSelect from "./SendSelectState";
import SendListUser from "./SendListUserState";

import TeacherTableBody from './TeacherTableBodyState'
import GraphDayCheck from './GraphDayCheckState'
import TeacherPopup from './TeacherPopupState'
import TeacherList from './TeacherListState'
import BellTableBody from './BellTableBodyState'
import ProcessBody from './ProcessBodyState'
import Burger from './BurgerState'

import Login from './LoginState'

const reducer = combineReducers({
    GroupPopup,
    GroupTable,
    GroupPopupDel,
    UserTable,
    UserPopup,
    UserList,
    UserPopupDel,
    SendFiles,
    SendListUser,
    SendSelect,
    TeacherPopup,
    TeacherTableBody,
    TeacherList,
    GraphDayCheck,
    BellTableBody,
    ProcessBody,
    Burger,
    Login
});

const store = createStore(reducer);

export default store