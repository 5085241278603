import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import { HashRouter } from "react-router-dom";

import 'bulma/css/bulma.css'
import './css/my.css';

import store from './reducers/index'
import App from './App';


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>,
    </Provider>,
    document.getElementById('root')
);